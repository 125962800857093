import { createApp } from 'vue'
import App from './App.vue'
import VueSmoothScroll from 'vue3-smooth-scroll'
import VueKinesis from 'vue-kinesis'
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

const app = createApp(App)
app.use(VueSmoothScroll)
app.use(VueKinesis)
app.component('scroll-parallax', ScrollParallax);
app.mount('#app')