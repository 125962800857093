<style>
@import "../../public/css/mainbody.css";
@import "../../public/css/mainnav.css";
</style>
<template>
  <div>
    <div>
      <header>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Kanit:400,600,700,900&amp;subset=latin-ext&amp;display=swap"
        />
      </header>
      <div id="bg-header"></div>
      
      <div id="bg-cloud2"></div>
    
      
      <div id="bg-header-ground" ></div>
      <scroll-parallax 
      direction="y"
      down="true"
      speed="0.5"
      >
      <div id="bg-header-cloud"></div>
      </scroll-parallax>
      <div
        class="
          nem-logo
          col-xxl-6 col-xl-7 col-lg-8 col-md-8 col-sm-9 col-12
          mx-auto
        "
      >
        <!-- <img class="img-fluid" style="z-index:2 !important;" src="images/header/logo_nem.svg" /> -->
      </div>
      
      <div id="navBarWrapper" class="pe-4">
        <nav id="mainNav" class="navbar navbar-expand-xl fixed-top">
          <!-- <nav class="fixed-top" id="mainNav"> -->
          <button
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            class="navbar-toggler navbar-toggler-right ms-auto"
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <!-- <font-awesome-icon icon="fa-solid fa-bars" /> -->
            <!-- <i class="fa fa-align-justify"></i> -->
            <img src="../../public/images/nav/btnToggle.png" />
          </button>
          <div
            id="navbarResponsive"
            class="collapse navbar-collapse bg-nav-collap"
          >
            <ul class="navbar-nav ms-auto" style="align: center">
              <li>
                <a
                  class="px-4"
                  href="#section-header"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarResponsive"
                  v-smooth-scroll="{
                    duration: 100,
                    offset: -70,
                    updateHistory: false,
                  }"
                  >HOME</a
                >
              </li>
              <li>
                <a
                  class="px-4"
                  href="#section-feature"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarResponsive"
                  v-smooth-scroll="{
                    duration: 100,
                    offset: 300,
                    updateHistory: false,
                  }"
                  >FEATURES</a
                >
              </li>
              <li>
                <a
                  class="px-4"
                  href="#section-tokenomic"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarResponsive"
                  v-smooth-scroll="{
                    duration: 100,
                    offset: -50,
                    updateHistory: false,
                  }"
                  >TOKENOMICS</a
                >
              </li>
              <li>
                <a
                  class="px-4"
                  href="#section-roadmap"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarResponsive"
                  v-smooth-scroll="{
                    duration: 100,
                    offset: -80,
                    updateHistory: false,
                  }"
                  >ROADMAP</a
                >
              </li>
              <li>
                <a class="px-4" href="https://docs.nem.wearejako.com/">PAPER</a>
              </li>
              <li>
                <a class="px-4" href>PARTNERS</a>
              </li>
              <li>
                <a
                  class="px-4"
                  href="#section-contact"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarResponsive"
                  v-smooth-scroll="{ duration: 100, updateHistory: false }"
                  >CONTACT</a
                >
              </li>
              <div id="play-btn-n"></div>
            </ul>
          </div>
        </nav>
      </div>
    </div>

    <section id="section-header">
      <div style="width:100%;">
      <div class="cloud-parallax-layer1 cloud-position d-none">
        <img class="img-fluid" src="images/header/cloud/cloud1.png"/>
      </div>
      <!-- <kinesis-element :strength="30">
          <div id="cloud-parallax-layer2" class="cloud-position" />
        </kinesis-element> -->
      <!-- <div class="cloud-parallax-layer3 cloud-position " /> -->
      <!-- <div class="cloud-parallax-layer3-2 cloud-position" />
      <div class="cloud-parallax-layer4 cloud-position" />
      <div class="cloud-parallax-layer5 cloud-position" />
      <div class="cloud-parallax-layer6 cloud-position" />
      <div class="cloud-parallax-layer7 cloud-position" />
      <div class="cloud-parallax-layer9 cloud-position" />
      <div class="cloud-parallax-layer8 cloud-position" /> -->
      </div>
    </section>

    <div id="header-decor-purple1" class="d-none d-lg-block">
      <img class="img-fluid" src="images/decor/dec_purple_R.svg" />
    </div>
    <div id="header-decor-blue1" class="d-none d-lg-block">
      <img class="img-fluid" src="images/decor/dec_blue_L.svg" />
    </div>
    <div id="header-decor-green1" class="d-none d-lg-block">
      <img class="img-fluid" src="images/decor/dec_green_L.svg" />
    </div>
    <div id="header-decor-green2" class="d-none d-lg-block">
      <img class="img-fluid" src="images/decor/dec_green_R.svg" />
    </div>
    <div id="header-decor-purple2" class="d-none d-lg-block">
      <img class="img-fluid" src="images/decor/dec_purple_L.svg" />
    </div>
    <div id="header-decor-blue2" class="d-none d-lg-block">
      <img class="img-fluid" src="images/decor/dec_blue.svg" />
    </div>

    <!-- <scroll-parallax class="d-none"
      direction="y"
      down="true"
      speed="0.45"
      id="header-decor-blue"
    >
      <img src="images/decor/dec_header_blue.svg" />
    </scroll-parallax>
    <scroll-parallax class="d-none"
      direction="y"
      down="true"
      speed="0.30"
      id="header-decor-green"
    >
      <img src="images/decor/dec_header_green.svg" />
    </scroll-parallax>-->

    <section id="section-info" class>
      <kinesis-container event="scroll">
        <div class="container">
          <div style="width: 0px; height: 0px">
            <!-- <div id="nem-allpost"></div> -->
          </div>

          <scroll-parallax
            class=""
            direction="y"
            down="true"
            speed="0.4"
            id="info-decor-purple"
          >
            <img
              class="img-fluid"
              src="images/decor/dec_purple.svg"
              style="transform: rotate(-15deg)"
            />
          </scroll-parallax>
          <scroll-parallax
            class=""
            direction="y"
            down="true"
            speed="0.2"
            id="info-decor-green"
          >
            <img
              class="img-fluid"
              src="images/decor/dec_green.svg"
              style="transform: rotate(-30deg)"
            />
          </scroll-parallax>
          <div id="nem-info-box" class="col-11 mx-auto">
            <div style="position: relative">
              <p id="nem-info-title" class="title-head-1 mb-4">WHAT IS NEM</p>
              <!-- <p id="nem-info-title-shadow">WHAT IS NEM</p> -->
            </div>
            <p class="content-text col-xs-12 col-sm-11 mx-auto">
              <br />NEM (Neo Era Monster) is a Play to earn style game with a
              strategy that allows players to enjoy the adventure. allocating
              things within the game or playing for Paid as an invaluable
              resource But most importantly, the game we developed to promote
              Jakoverse's system as the main system. We are confident that both
              Jakoverse and NEM projects will be successful in the long term in
              the future.
              <br />
              <br />
            </p>
            <div style="width: 100%; height: 100px">
              <div id="bg-object-info">
                <!-- <img
                  id="bg-image-info"
                  class="img-fluid"
                  src="images/header/info-pic.jpg"
                /> -->
                <div class="ratio ratio-16x9">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/iXyUAP1UdpU?controls=0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <!-- <img id="cha-image-info" src="images/header/pose_all_mini.png" /> -->
              </div>
            </div>
          </div>
        </div>
      </kinesis-container>
    </section>
    <section id="section-feature">
      <div class="container">
        <div style="/*display: flex*/">
          <div id="logo-decor1" />
          <p id="feature-title" class="title-head-1">FEATURES</p>
        </div>
        <!-- screen > lg -->
        <div id="nem-feature-box" class="d-none d-xl-block d-lg-block">
          <vueper-slides
            :infinite="true"
            :arrows="false"
            :pause-on-hover="pauseOnHover"
            :visible-slides="3"
            slide-multiple
            fixedHeight="700px"
            :aria-label="`featureSlide`"
            :dragging-distance="80"
            :touchable="true"
            class="no-shadow"
          >
            <vueper-slide v-for="(slidegame, i) in slidefeature" :key="i">
              <template #content>
                <div class="grid-slide-feature">
                  <div class="div-table">
                    <div class="div-table-row">
                      <div class="div-table-col">
                        <div v-html="slidegame.col_bg_c[0]"></div>
                        <div v-html="slidegame.col_img[0]"></div>
                        <div class="feature-text-box">
                          <p class="feature-text-title">
                            {{ slidegame.col_title[0] }}
                          </p>
                          <p class="feature-text-detail">
                            {{ slidegame.col_detail[0] }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="div-table-row">
                      <div class="div-table-col">
                        <div v-html="slidegame.col_bg_c[1]"></div>
                        <div v-html="slidegame.col_img[1]"></div>
                        <div class="feature-text-box">
                          <p class="feature-text-title">
                            {{ slidegame.col_title[1] }}
                          </p>
                          <p class="feature-text-detail">
                            {{ slidegame.col_detail[1] }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #bullet="{ bulletIndexes, goToSlide, currentSlide }">
                <span
                  v-for="(slideIndex, i) in bulletIndexes"
                  :key="i"
                  :class="{ active: currentSlide === slideIndex }"
                  @click="goToSlide(slideIndex)"
                >
                  <i class="icon" style="margin-top: 10px">{{
                    active ? "check_circle" : "radio_button_unchecked"
                  }}</i>
                </span>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
        <!-- screee md -->
        <div id="nem-feature-box" class="d-lg-none d-md-block d-none">
          <vueper-slides
            :infinite="true"
            :arrows="false"
            :pause-on-hover="pauseOnHover"
            :visible-slides="2"
            slide-multiple
            fixedHeight="700px"
            :aria-label="`featureSlide`"
            :dragging-distance="80"
            :touchable="true"
            class="no-shadow"
          >
            <vueper-slide v-for="(slidegame, i) in slidefeature" :key="i">
              <template #content>
                <div class="grid-slide-feature">
                  <div class="div-table">
                    <div class="div-table-row">
                      <div class="div-table-col">
                        <div v-html="slidegame.col_bg_c[0]"></div>
                        <div v-html="slidegame.col_img[0]"></div>
                        <div class="feature-text-box">
                          <p class="feature-text-title">
                            {{ slidegame.col_title[0] }}
                          </p>
                          <p class="feature-text-detail">
                            {{ slidegame.col_detail[0] }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="div-table-row">
                      <div class="div-table-col">
                        <div v-html="slidegame.col_bg_c[1]"></div>
                        <div v-html="slidegame.col_img[1]"></div>
                        <div class="feature-text-box">
                          <p class="feature-text-title">
                            {{ slidegame.col_title[1] }}
                          </p>
                          <p class="feature-text-detail">
                            {{ slidegame.col_detail[1] }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #bullet="{ bulletIndexes, goToSlide, currentSlide }">
                <span
                  v-for="(slideIndex, i) in bulletIndexes"
                  :key="i"
                  :class="{ active: currentSlide === slideIndex }"
                  @click="goToSlide(slideIndex)"
                >
                  <i class="icon" style="margin-top: 10px">{{
                    active ? "check_circle" : "radio_button_unchecked"
                  }}</i>
                </span>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
        <!-- screee sm -->
        <div id="nem-feature-box" class="d-md-none d-sm-block d-none">
          <vueper-slides
            :infinite="true"
            :arrows="false"
            :pause-on-hover="pauseOnHover"
            :visible-slides="1"
            slide-multiple
            fixedHeight="730px"
            :aria-label="`featureSlide`"
            :dragging-distance="80"
            :touchable="true"
            class="no-shadow"
          >
            <vueper-slide v-for="(slidegame, i) in slidefeature" :key="i">
              <template #content>
                <div class="grid-slide-feature">
                  <div class="div-table">
                    <div class="div-table-row">
                      <div class="div-table-col">
                        <div v-html="slidegame.col_bg_c[0]"></div>
                        <div v-html="slidegame.col_img[0]"></div>
                        <div class="feature-text-box">
                          <p class="feature-text-title">
                            {{ slidegame.col_title[0] }}
                          </p>
                          <p class="feature-text-detail">
                            {{ slidegame.col_detail[0] }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="div-table-row">
                      <div class="div-table-col">
                        <div v-html="slidegame.col_bg_c[1]"></div>
                        <div v-html="slidegame.col_img[1]"></div>
                        <div class="feature-text-box">
                          <p class="feature-text-title">
                            {{ slidegame.col_title[1] }}
                          </p>
                          <p class="feature-text-detail">
                            {{ slidegame.col_detail[1] }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #bullet="{ bulletIndexes, goToSlide, currentSlide }">
                <span
                  v-for="(slideIndex, i) in bulletIndexes"
                  :key="i"
                  :class="{ active: currentSlide === slideIndex }"
                  @click="goToSlide(slideIndex)"
                >
                  <i class="icon" style="margin-top: 10px">{{
                    active ? "check_circle" : "radio_button_unchecked"
                  }}</i>
                </span>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
        <!-- screee xs mobile phone -->
        <div id="nem-feature-box" class="d-sm-none">
          <vueper-slides
            :infinite="true"
            :arrows="false"
            :pause-on-hover="pauseOnHover"
            :visible-slides="1"
            slide-multiple
            fixedHeight="710px"
            :aria-label="`featureSlide`"
            :dragging-distance="80"
            :touchable="true"
            class="no-shadow"
          >
            <vueper-slide v-for="(slidegame, i) in slidefeature" :key="i">
              <template #content>
                <div class="grid-slide-feature">
                  <div class="div-table">
                    <div class="div-table-row">
                      <div class="div-table-col">
                        <div v-html="slidegame.col_bg_c[0]"></div>
                        <div v-html="slidegame.col_img[0]"></div>
                        <div class="feature-text-box">
                          <p class="feature-text-title">
                            {{ slidegame.col_title[0] }}
                          </p>
                          <p class="feature-text-detail">
                            {{ slidegame.col_detail[0] }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="div-table-row">
                      <div class="div-table-col">
                        <div v-html="slidegame.col_bg_c[1]"></div>
                        <div v-html="slidegame.col_img[1]"></div>
                        <div class="feature-text-box">
                          <p class="feature-text-title">
                            {{ slidegame.col_title[1] }}
                          </p>
                          <p class="feature-text-detail">
                            {{ slidegame.col_detail[1] }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #bullet="{ bulletIndexes, goToSlide, currentSlide }">
                <span
                  v-for="(slideIndex, i) in bulletIndexes"
                  :key="i"
                  :class="{ active: currentSlide === slideIndex }"
                  @click="goToSlide(slideIndex)"
                >
                  <i class="icon" style="margin-top: 10px">{{
                    active ? "check_circle" : "radio_button_unchecked"
                  }}</i>
                </span>
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
      </div>
    </section>
    <section id="section-nemcharac">
      <div class="container">
        <scroll-parallax
          class=""
          direction="y"
          down="true"
          speed="0.15"
          id="charac-decor-green"
        >
          <img
            class="img-fluid"
            src="images/decor/dec_green.svg"
            style="transform: rotate(-15deg)"
          />
        </scroll-parallax>
        <div id="charac_title">CHARACTERS</div>
        <div id="charac_detail" class="col-lg-10 col-11">
          Players need to recruit characters within the game to perform various
          activities. Characters will have a specific occupation. And players
          can use it to collect experience in order to gain more benefits.
        </div>
        <div class="row">
          <div id="charac_slide_box" class="col-12">
            <div id="charac_slide_bg" class="cha_slide_bgwhite_sm">
              <!-- <div class="charac_tb d-none">
              <div class="charac-row">
                <div id="charac_button_1"></div>
              </div>
              <div class="charac-row">
                <div id="charac_button_2"></div>
              </div>
              <div class="charac-row">
                <div id="charac_button_3"></div>
              </div>
            </div>
            <div></div> -->
            </div>
            <vueper-slides
              fade
              :infinite="true"
              :arrows="false"
              :autoplay="false"
              :pause-on-hover="pauseOnHover"
              fixedHeight="800px"
              :aria-label="`characSlide`"
              :touchable="true"
              class="no-shadow"
            >
              <vueper-slide v-for="(slidegame, i) in slidecharacter" :key="i">
                <template #content>
                  <div v-html="slidegame.charac_bgc"></div>
                  <div id="charac_slide_bgImg" class="charac_slide_bg_po"></div>
                  <div v-html="slidegame.charac_bgimg"></div>
                </template>
                <template #bullet="{ bulletIndexes, goToSlide, currentSlide }">
                  <span
                    v-for="(slideIndex, i) in bulletIndexes"
                    :key="i"
                    :class="{ active: currentSlide === slideIndex }"
                    @click="goToSlide(slideIndex)"
                  >
                    <i class="icon">
                      {{ active ? "check_circle" : "radio_button_unchecked" }}
                    </i>
                  </span>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
      </div>
    </section>

    <section id="section-tokenomic" class="">
      <div class="container">
        <div class="row">
          <div class="col-xxl-5 col-xl-4 d-xl-block d-none">
            <img class="img-fluid" src="images/tokenomic/tokenomic_graph.svg" />
            <div id="token-total-text">
              <p>Total</p>
              <p>100,000,000</p>
            </div>
          </div>
          <div class="col-xl-7 col-lg-8 col-12 mx-auto mt-4">
            <!-- if width >= xl screen -->
            <div
              id="token-title-detail"
              class="d-xl-block d-none title-head-1 text-white"
              style="text-align: left"
            >
              TOKENOMICS
            </div>
            <!-- if width < xl screen -->
            <div
              id="token-title-detail"
              class="d-xl-none title-head-1 text-white"
            >
              TOKENOMICS
            </div>
            <div class="row">
              <div
                id="token-border-1"
                class="ms-auto ms-md-0 col-md-6 col-11 token-detail-box"
              >
                <div class="token-detail-text">
                  <!-- <p>Play to earn &amp; Staking reward<br /></p> -->
                </div>
                <div class="token-detail-percen">
                  <!-- <p>65%</p> -->
                </div>
              </div>
              <div
                id="token-border-2"
                class="ms-auto ms-md-0 col-md-6 col-11 token-detail-box"
              >
                <div class="token-detail-text">
                  <!-- <p>Reserve<br /></p> -->
                </div>
                <div class="token-detail-percen">
                  <!-- <p>20%</p> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div
                id="token-border-3"
                class="ms-auto ms-md-0 col-md-6 col-11 token-detail-box"
              >
                <div class="token-detail-text">
                  <!-- <p>IDO<br /></p> -->
                </div>
                <div class="token-detail-percen">
                  <!-- <p>6.5%</p> -->
                </div>
              </div>
              <div
                id="token-border-4"
                class="ms-auto ms-md-0 col-md-6 col-11 token-detail-box"
              >
                <div class="token-detail-text">
                  <!-- <p>Dex liquidity<br /></p> -->
                </div>
                <div class="token-detail-percen">
                  <!-- <p>6.5%</p> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div
                id="token-border-5"
                class="ms-auto ms-md-0 col-md-6 col-11 token-detail-box"
              >
                <div class="token-detail-text">
                  <!-- <p>Airdrop</p> -->
                </div>
                <div class="token-detail-percen">
                  <!-- <p>2%</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="section-ecosystem">
        <div class="container">
          <scroll-parallax
            direction="y"
            down="true"
            speed="0.1"
            id="eco-decor-green"
          >
            <img
              class="img-fluid"
              src="images/decor/dec_green.svg"
              style="transform: rotate(-50deg)"
            />
          </scroll-parallax>
          <scroll-parallax
            direction="y"
            down="true"
            speed="0.4"
            id="eco-decor-purple"
          >
            <img class="img-fluid" src="images/decor/dec_purple.svg" />
          </scroll-parallax>
          <!-- </scroll-parallax> -->
          <div
            id="eco-title"
            class="nem-head-title title-head-1 text-left mb-4"
          >
            ECOSYSTEM
            <!-- <p id="p-eco-title">ECOSYSTEM</p> -->
            <!-- <p id="p-eco-title-shadow">ECOSYSTEM</p> -->
          </div>
          <div id="eco-img">
            <img class="img-fluid" src="images/tokenomic/ecosys.png" />
          </div>
        </div>
      </div>
    </section>
    <section id="section-roadmap" class>
      <div class="container">
        <div class="title-head-1 text-center xs-mt-50">
          <p>ROADMAP</p>
        </div>
        <div class="d-flex d-lg-none d-block">
          <div class="col-5">
            <img class="img-fluid" src="images/roadmap/stage1.png" />
          </div>
          <div class="col-7 mt-5 stage-box-mini">
            <ul class="stagebullet-1">
              <li>
                Research Game and NFT
                <br />
              </li>
              <li>NFT Design &amp; Develop Neo Era Monster</li>
              <li>Release Neo Era Monster Website and Social media</li>
            </ul>
          </div>
        </div>
        <div class="d-flex d-lg-none d-block">
          <div class="col-7 mt-5 stage-box-mini">
            <ul class="stagebullet-2">
              <li>
                Airdrop $NEMH
                <br />
              </li>
              <li>
                IDO Whitelist of $NEMH
                <br />
              </li>
              <li>
                Public Sale $NEMH Token
                <br />
              </li>
              <li>
                Release NEM V1 on Browser
                <br />
              </li>
              <li>
                Release Monster Arena
                <br />
              </li>
            </ul>
          </div>
          <div class="col-5">
            <img class="img-fluid" src="images/roadmap/stage2.png" />
          </div>
        </div>
        <div class="d-flex d-lg-none d-block justify-content-center">
          <div class="col-5">
            <img class="img-fluid" src="images/roadmap/stage3.png" />
          </div>
          <div class="col-7 mt-5 stage-box-mini">
            <ul class="stagebullet-3">
              <li>
                Airdrop $NEMF
                <br />
              </li>
              <li>
                IDO Whitelist of $NEMF
                <br />
              </li>
              <li>
                Public Sale $NEMF Token
                <br />
              </li>
              <li>
                Release NEM&nbsp; V2 on Browser
                <br />
              </li>
              <li>
                Release Restore Energy System
                <br />
              </li>
              <li>
                Release $NEMF Yield Farming
                <br />
              </li>
              <li>
                Release Lottery
                <br />
              </li>
            </ul>
          </div>
        </div>
        <div class="d-flex d-lg-none d-block">
          <div class="col-7 mt-5 stage-box-mini">
            <ul class="stagebullet-4">
              <li>
                Airdrop $NEME
                <br />
              </li>
              <li>
                IDO Whitelist of $NEME
                <br />
              </li>
              <li>
                NEM V3 on Browser
                <br />
              </li>
              <li>
                Equipment System
                <br />
              </li>
              <li>
                Release Durability System
                <br />
              </li>
              <li>
                Release $NEME Yield Farming
                <br />
              </li>
              <li>
                Release Event :&nbsp; Monsters attack the city
                <br />
              </li>
            </ul>
          </div>
          <div class="col-5">
            <img class="img-fluid" src="images/roadmap/stage4.png" />
          </div>
        </div>
        <div class="d-flex d-lg-none d-block">
          <div class="col-5">
            <img class="img-fluid" src="images/roadmap/stage5.png" />
          </div>
          <div class="col-7 mt-5 stage-box-mini">
            <ul class="stagebullet-4">
              <li>
                Release Mobile Version
                <br />
              </li>
              <li>
                Etc…
                <br />
              </li>
            </ul>
          </div>
        </div>
        <div id="roadmap-img" class="d-none d-lg-block">
          <img class="img-fluid" src="images/roadmap/roadmap-r.svg" />
        </div>
        <div class="d-flex">
          <div class="stage-box d-none d-lg-block col-20 mt-upper-roadmap">
            <ul class="stagebullet-1">
              <li>
                Research Game and NFT
                <br />
              </li>
              <li>NFT Design &amp; Develop Neo Era Monster</li>
              <li>Release Neo Era Monster Website and Social media</li>
            </ul>
          </div>
          <div class="stage-box d-none d-lg-block col-20">
            <ul class="stagebullet-2">
              <li>
                Airdrop $NEMH
                <br />
              </li>
              <li>
                IDO Whitelist of $NEMH
                <br />
              </li>
              <li>
                Public Sale $NEMH Token
                <br />
              </li>
              <li>
                Release NEM V1 on Browser
                <br />
              </li>
              <li>
                Release Monster Arena
                <br />
              </li>
            </ul>
          </div>
          <div class="stage-box d-none d-lg-block col-20 mt-upper-roadmap">
            <ul class="stagebullet-3">
              <li>
                Airdrop $NEMF
                <br />
              </li>
              <li>
                IDO Whitelist of $NEMF
                <br />
              </li>
              <li>
                Public Sale $NEMF Token
                <br />
              </li>
              <li>
                Release NEM&nbsp; V2 on Browser
                <br />
              </li>
              <li>
                Release Restore Energy System
                <br />
              </li>
              <li>
                Release $NEMF Yield Farming
                <br />
              </li>
              <li>
                Release Lottery
                <br />
              </li>
            </ul>
          </div>
          <div class="stage-box d-none d-lg-block col-20">
            <ul class="stagebullet-4">
              <li>
                Airdrop $NEME
                <br />
              </li>
              <li>
                IDO Whitelist of $NEME
                <br />
              </li>
              <li>
                NEM V3 on Browser
                <br />
              </li>
              <li>
                Equipment System
                <br />
              </li>
              <li>
                Release Durability System
                <br />
              </li>
              <li>
                Release $NEME Yield Farming
                <br />
              </li>
              <li>
                Release Event :&nbsp; Monsters attack the city
                <br />
              </li>
            </ul>
          </div>
          <div class="stage-box d-none d-lg-block col-20 mt-upper-roadmap">
            <ul class="stagebullet-4">
              <li>
                Release Mobile Version
                <br />
              </li>
              <li>
                Etc…
                <br />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="section-contact" class="justify-content-center">
      <div class="container">
        <div class="po-decor-2">
          <div id="logo-decor2" />
        </div>
        <div class="row">
          <div class="col-12 col-lg-2">
            <div class="po-logo-foot">
              <div id="logo-contact-img" />
            </div>
            <div class="col-6 d-lg-none d-block mx-auto mt-3">
              <img class="img-fluid" src="images/contact/logo_nem_m.svg" />
            </div>
            <div
              class="row d-flex justify-content-center mx-auto"
              style="height: 100px; width: 350px"
            >
              <div class="social-icon m-1">
                <a href="#">
                  <img
                    class="img-fluid"
                    src="images/contact/logo_telegram.svg"
                  />
                </a>
              </div>
              <div class="social-icon m-1">
                <a href="#">
                  <img
                    class="img-fluid"
                    src="images/contact/logo_twitter.svg"
                  />
                </a>
              </div>
              <div class="social-icon m-1">
                <a href="#">
                  <img class="img-fluid" src="images/contact/logo_face.svg" />
                </a>
              </div>
              <div class="social-icon m-1">
                <a href="#">
                  <img class="img-fluid" src="images/contact/logo_mail.svg" />
                </a>
              </div>
            </div>
          </div>
          <div id="style-link-contact" class="mt-lg-5 col-12 col-lg-2">
            <div>
              <ul class="contact-text-ul">
                <li>
                  <a href="#">FEATURES</a>
                </li>
                <li>
                  <a href="#">TOKENOMICS</a>
                </li>
                <li>
                  <a href="#">ROADMAP</a>
                </li>
              </ul>
            </div>
            <div>
              <ul class="contact-text-ul">
                <li>
                  <a href="#">PAPER</a>
                </li>
                <li>
                  <a href="#">PARTNERS</a>
                </li>
                <li>
                  <a href="#">CONTACT</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="contact-us-copy">
          CopyrightⒸ 2022 WE ARE JAKO •
          <span class="textcolor-green">support@wearejako.com</span>• All rights
          reserved.
        </div>
      </div>
    </section>
  </div>
</template>
<script>
/* call use data&image by json file  */
import jsonslide from "../JsonFile/slide_data.json";
/* call use data&image by json file  */
/* call slides by VueperSliders */
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
/*
 call use responesive by VUE3MQ
 import { MqResponsive } from "vue3-mq";*/

export default {
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      slidefeature: jsonslide[0].slidefeature,
      slidecharacter: jsonslide[1].slidecharacter,
    };
  },
};
</script>
<script type="text/javascript">
</script>