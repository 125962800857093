<template>
  <div>
  <NemBody/>
  </div>
</template>

<script>
import NemBody from './components/NemBody.vue'


export default {
  name: 'App',
  components: {
    NemBody
  }
}
</script>

<style>
#app {
  font-family: 'Kanit';
}

</style>
